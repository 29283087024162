@font-face {
  font-family: "Calibri";
  src: local("Calibri"),
   url("./fonts/Calibri Regular.ttf") format("truetype");
  font-weight: bold;
  }

html {
    scroll-behavior: smooth;
    font-family: 'Calibri';
  }

  #root {
    background: #fff;
    font-family: 'Calibri';
  }